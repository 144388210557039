import { Config } from "../../../environment";
import GetPropertySnapshotModel from "../../../models/api/properties/GetPropertySnapshotModel";
import GetPreValuationModel from "../../../models/api/valuations/GetPreValuationModel";
import GetValuationModel from "../../../models/api/valuations/GetValuationModel";
import SetValuationModel from "../../../models/api/valuations/SetValuationModel";

import PropertyModel from "../../../models/api/valuations/stepTypes/PropertyModel";
import ValuationModel from "../../../models/api/valuations/stepTypes/ValuationModel";
import PropertySnapshotModel from "../../../models/api/valuations/stepTypes/PropertySnapshotModel";
import ProcessTempValuationRequest from "../../../models/api/valuations/stepTypes/ProcessTempValuationRequest";
import ProcessTempValuationResponse from "../../../models/api/valuations/stepTypes/ProcessTempValuationResponse";
import CloneTempValuationRequest from "../../../models/api/valuations/stepTypes/CloneTempValuationRequest";

import CreateValuationAddress from "../../../models/api/valuations/stepTypes/CreateValuationAddress";
import CreateValuationAssessment from "../../../models/api/valuations/stepTypes/CreateValuationAssessment";
import CreateValuationClassification from "../../../models/api/valuations/stepTypes/CreateValuationClassification";
import ResumeTempValuationRequest from "../../../models/api/valuations/stepTypes/ResumeTempValuationRequest";

import IHttpClient from "../../httpclient/IHttpClient";
import APIBase from "../APIBase";

export default class ValuationFormAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  SubmitPropertyDetails(
    model: Record<string, unknown>
  ): Promise<GetPropertySnapshotModel> {
    return this.POST({
      Path: "/api/valuation_form/property_details",
      Body: model,
    });
  }

  SavePropertyDetails(
    model: Record<string, unknown>,
    propertySnapShotId: string
  ): Promise<GetPropertySnapshotModel> {
    return this.POST({
      Path: `/api/valuation_form/${propertySnapShotId}/save_property_details`,
      Body: model,
    });
  }

  PrepaymentProcessSubmission(model: any): Promise<GetPreValuationModel> {
    return this.POST({
      Path: "/api/valuation_form/prepayment_process_submission",
      Body: {
        Address: model.AddressDetails.Address,
        CreateDate: model.AssessmentDetails.CreateDate,
        Purpose: model.AssessmentDetails.Purpose,
        RetrospectiveDate: model.AssessmentDetails.RetrospectiveDate,
        BusinessName: model.Contact.BusinessName,
        Email: model.Contact.Email,
        FirstName: model.Contact.FirstName,
        LastName: model.Contact.LastName,
        Phone: model.Contact.Phone,
        PropertyClassification:
          model.PropertyTypeSelection.PropertyClassification,
        PropertyType: model.PropertyTypeSelection.PropertyType,
      },
    });
  }

  CreatePreValuationForm(model: any): Promise<GetPreValuationModel> {
    return this.POST({
      Path: "/api/valuation_form/create_prevaluation_form",
      Body: {
        Address: model.AddressDetails.Address,
        CreateDate: model.AssessmentDetails.CreateDate,
        Purpose: model.AssessmentDetails.Purpose,
        RetrospectiveDate: model.AssessmentDetails.RetrospectiveDate,
        BusinessName: model.Contact.BusinessName,
        Email: model.Contact.Email,
        FirstName: model.Contact.FirstName,
        LastName: model.Contact.LastName,
        Phone: model.Contact.Phone,
        PropertyClassification:
          model.PropertyTypeSelection.PropertyClassification,
        PropertyType: model.PropertyTypeSelection.PropertyType,
      },
    });
  }

  CreateValuation(
    snapshotId: string,
    purpose: string,
    invoiceId: string,
    retrospectiveDate: string,
    repUserId: string
  ): Promise<GetValuationModel> {
    return this.POST({
      Path: `/api/valuation_form/valuations`,
      Body: {
        SnapshotId: snapshotId,
        Purpose: purpose,
        InvoiceId: invoiceId,
        PracticeId: Config.PracticeId,
        RetrospectiveDate: retrospectiveDate,
        RepUserId: repUserId,
      },
    });
  }

  CreateValuationAddress(
    model: CreateValuationAddress
  ): Promise<PropertyModel> {
    return this.POST({
      Path: "/api/valuation_form/create_valuation_address",
      Body: model,
    });
  }

  CreateValuationAssessment(
    model: CreateValuationAssessment
  ): Promise<ValuationModel> {
    return this.POST({
      Path: "/api/valuation_form/create_valuation_assessment",
      Body: model,
    });
  }

  CreateValuationClassification(
    model: CreateValuationClassification
  ): Promise<PropertySnapshotModel> {
    return this.POST({
      Path: "/api/valuation_form/create_valuation_classification",
      Body: model,
    });
  }

  ResumeTempValuation(
    model: ResumeTempValuationRequest,
  ): Promise<ProcessTempValuationResponse> {
    return this.POST({
      Path: "/api/valuation_form/resume_temp_valuation",
      Body: model,
    });
  }

  ProcessTempValuation(
    model: ProcessTempValuationRequest
  ): Promise<ProcessTempValuationResponse> {
    return this.POST({
      Path: "/api/valuation_form/process_temp_valuation",
      Body: model,
    });
  }

  CompleteTempValuation(
    model: ProcessTempValuationRequest
  ): Promise<ProcessTempValuationResponse> {
    return this.POST({
      Path: "/api/valuation_form/complete_temp_valuation",
      Body: model,
    });
  }

  CloneTempValuation(
    model: CloneTempValuationRequest
  ): Promise<ProcessTempValuationResponse> {
    return this.POST({
      Path: "/api/valuation_form/clone_temp_valuation",
      Body: model,
    });
  }
}
